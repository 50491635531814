<template>
  <div class="ebpay-box">
    <h2>{{ $t('components.three.584299-0') }}</h2>
    <div class="ebpay-time">{{ conutdown }}</div>
    <p>
      {{ $t('components.three.584299-1') }}<br />{{ $t('components.three.584299-2') }}
      <span class="text-blue" @click="openContact(baseInfo[6].configValue)"
        >{{ $t('components.three.584299-3') }}</span
      >
    </p>
    <div class="ebpay-tips">
      <!-- <img src="@/assets/images/userinfo/tips.png" /> -->
      {{ $t('components.three.584299-4') }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conutdown: "30:00",
      countdownTime: 1800,
    };
  },
  mounted() {
    this.countTime();
  },
  methods: {
    countTime() {
      this.timer = setTimeout(() => {
        this.countdownTime--;
        var minute = parseInt(this.countdownTime / 60);
        var second = parseInt(this.countdownTime % 60);
        this.conutdown = minute + ":" + (second < 10 ? "0" + second : second);
        if (this.countdownTime === 0) {
          clearTimeout(this.timer);
        }
        this.countTime();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.ebpay-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px !important;
  text-align: center;

  h2 {
    font-weight: normal;
  }
}

.ebpay-time {
  width: 129px;
  height: 129px;
  background: url("@/assets/pic/time.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  font-size: 26px;
}

.ebpay-tips {
  display: flex;
  align-items: center;
  margin-top: 24px;

  img {
    width: 18px;
    margin-right: 4px;
  }
}
.text-blue {
  color: var(--text-green2);
  cursor: pointer;
}
</style>
