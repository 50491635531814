<template>
  <section class="jf-head">
    <div class="jf-wid-14 flex-between">
      <div class="left">
        <span class="item" @click="$router.push('/')"
          ><i class="icon-home icon"></i
          >{{ $t("components.Head.949315-0") }}</span
        >
        <div class="tab-lang" @click="tabLang">
          <img
            v-if="targetLang"
            :src="require('@/assets/pic/national/' + targetLang + '.webp')"
            class="icon"
          />
          <span class="arrow"></span>
        </div>
      </div>
      <div class="right" v-if="isLogin">
        <div class="flex-center" style="gap: 0.1rem">
          <span class="money" v-if="accountMoney">{{
            isEyy ? accountMoney.userBalance.toFixed(2) : "*****"
          }}</span>
          <span
            class="icon-eye"
            :class="{ 'icon-eye-close': !isEyy }"
            @click="showMoney"
          ></span>
        </div>
        <span class="icon-refresh" @click="getUserBalance"></span>
        <div class="save" @click="judgeMoblieOrPayPwd(false, 1)">
          {{ $t("components.Head.949315-1") }}
        </div>
        <div class="info">
          <span class="icon-user"></span>
          <div class="head-login-msg">
            <ul>
              <li
                v-for="(item, index) in menus"
                :key="index"
                @click="openLink(item)"
              >
                <span class="icon" :class="item.icon"></span>
                <span class="text">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right" v-else>
        <span class="money" @click="openRegiter">{{
          $t("components.Head.949315-2")
        }}</span>
        <div class="save" @click="openLoginPop">
          {{ $t("components.Head.949315-3") }}
        </div>
      </div>
    </div>
    <!-- 偏好设置 -->
    <div class="pop-lang" v-if="isLang">
      <div class="pop-main">
        <div class="pop-lang-title">
          <div class="left">{{ $t("mixins.index.638921-7") }}</div>
          <div class="icon-close" @click="isLang = false"></div>
        </div>
        <ul class="pop-lang-list">
          <li
            v-for="(item, index) in langs"
            :key="index"
            :class="{ hover: item.name === targetLang }"
            @click="selectLang(item)"
          >
            <img
              :src="require('@/assets/pic/national/' + item.value + '.webp')"
            />
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { removeToken } from "@/utils/token";
export default {
  data() {
    return {
      isEyy: true,
      menus: [
        {
          name: this.$t("components.Head.949315-1"),
          link: "",
          icon: "icon-purse",
          type: 1,
        },
        {
          name: this.$t("components.Head.949315-4"),
          link: "",
          icon: "icon-round-transfer",
          type: 2,
        },
        {
          name: this.$t("components.Head.949315-5"),
          link: "",
          icon: "icon-card",
          type: 3,
        },
        {
          name: this.$t("components.Head.949315-6"),
          link: "/userInfo/bet",
          icon: "icon-record",
        },
        {
          name: this.$t("components.Head.949315-7"),
          link: "/userInfo/transaction",
          icon: "icon-round-transfer",
        },
        {
          name: this.$t("components.Head.949315-8"),
          link: "/userInfo/index",
          icon: "icon-user",
        },
        {
          name: this.$t("components.Head.949315-9"),
          link: "/userInfo/message",
          icon: "icon-message",
        },
        {
          name: this.$t("components.Head.949315-10"),
          link: "",
          icon: "icon-exit",
          type: 0,
        },
      ],
      targetLang: "",
      isLang: false,
      langs: null,
    };
  },
  computed: {
    ...mapState(["accountMoney", "langList"]),
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.getUserBalance();
      }
    },
    userInfo(val) {
      if (val) {
        this.langList;
        this.loginLangText();
      }
    },
  },
  mounted() {
    this.getUserBalance();
    this.getSysLang();
    this.watchROuter();
  },
  methods: {
    watchROuter() {
      const { invitationCode } = this.$route.query;
      if (invitationCode) {
        this.openRegiter();
      }
    },
    // 登录之后更新头部语言选择框
    loginLangText() {
      if (this.isLogin) {
        const lang = this.langList.find(
          (item) => item.value === this.userInfo.languageType
        );
        if (lang) {
          this.targetLang = lang.value;
        }
      }
    },
    selectLang(val) {
      localStorage.setItem("lang", val.type);
      localStorage.setItem("langParam", val.value);
      this.$store.dispatch("setLangTypeValAc", val.value);
      this.targetLang = val.value;
      // 设置语言
      this.$i18n.locale = val.type;
      this.isLang = false;
      // 后端接口语言
      if (this.isLogin) {
        this.changeLanguageAndCurrenc(val.value, "lang");
      }
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          this.isLang = false;
          location.reload();
        }
      });
    },
    tabLang() {
      this.isLang = true;
    },
    getSysLang() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "language_type" })
        .then((res) => {
          if (res) {
            res.sysDictionaryList.forEach((item) => {
              const obj = this.langList.find((row) => row.value === item.value);
              item.type = obj ? obj.type : "zh";
            });
            this.langs = res.sysDictionaryList;
            const targetLang = localStorage.getItem("lang");
            if (targetLang) {
              const langVal = this.langList.find(
                (item) => item.type === targetLang
              );
              this.targetLang = langVal.value;
            } else {
              this.targetLang = res.sysDictionaryList[0].value;
            }
          }
        });
    },
    openLink(val) {
      if (val.type === 0) {
        this.loginOut();
        return;
      }
      if (val.type === 1) {
        this.judgeMoblieOrPayPwd(false, 1);
        return;
      }
      if (val.type === 2) {
        this.$store.dispatch("setTransferAc", true);
        return;
      }
      if (val.type === 3) {
        this.judgeMoblieOrPayPwd(false, 3);
        return;
      }
      if (val.link) {
        this.openUrl(val.link);
      }
    },
    loginOut() {
      this.$Api.logout().then((res) => {
        if (res) {
          this.$message({
            type: "success",
            message: this.$t("components.Head.949315-11"),
          });
          this.$store.dispatch("setUserInfoAc", null);
          this.$store.dispatch("setHasLoginAc", false);
          removeToken();
          location.reload();
        }
      });
    },
    openRegiter() {
      this.$store.dispatch("setIsLoginShowAc", {
        show: true,
        type: "register",
      });
    },
    showMoney() {
      this.isEyy = !this.isEyy;
    },
    getUserBalance() {
      this.$loading.show();
      if (!this.isLogin) return;
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$loading.hide();
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg-1);
  padding: 0.1rem 0;
  height: 0.64rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  .left {
    display: flex;
    align-items: center;
    gap: 0.14rem;
    .item {
      cursor: pointer;
      color: var(--text);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.04rem;
    }
    .icon {
      font-size: 0.14rem;
      color: var(--text-1);
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 0.16rem;
    span {
      cursor: pointer;
    }
    .info {
      color: var(--text);
      position: relative;
      z-index: 10;
      &:hover {
        .head-login-msg {
          display: block;
        }
      }
    }
  }
  .save {
    color: var(--text);
    background: #009d81;
    padding: 0.1rem 0.2rem;
    border-radius: 0.01rem;
    font-size: 0.14rem;
    cursor: pointer;
  }
}
.head-login-msg {
  display: none;
  position: absolute;
  top: 0.3rem;
  right: 0;
  border-radius: 0.1rem;
  background: white;
  &::before {
    content: "";
    position: absolute;
    top: -0.2rem;
    left: 0;
    right: 0;
    height: 0.2rem;
    background: transparent;
  }
  ul {
    padding: 0.12rem 0.2rem;
    border-radius: 0.1rem;
    cursor: auto;
    background-color: #fff;
    width: 2rem;
    color: #9297ab;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  li {
    display: flex;
    align-items: center;
    height: 0.54rem;
    width: 100%;
    font-size: 0.14rem;
    gap: 0.16rem;
    font-size: 0.14rem;
    padding: 0 0.1rem;
    .icon {
      font-size: 0.18rem;
    }
    &:last-child {
      border-top: 0.01rem solid #e1e3ea;
    }
  }
}
.tab-lang {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 0.1rem;
  cursor: pointer;
  gap: 0.06rem;
  .icon {
    width: 0.24rem;
  }
  .arrow {
    border: 5px solid transparent;
    border-top-color: white;
    border-bottom: 0;
  }
}
.pop-lang {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pop-main {
  background: #24262b;
  width: 4.38rem;
  border-radius: 0.04rem;
  padding: 0.2rem;
}
.pop-lang-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    color: #009d81;
    font-size: 0.16rem;
  }
  .icon-close {
    cursor: pointer;
  }
}
.pop-lang-list {
  display: flex;
  flex-direction: column;
  gap: 0.16rem;
  margin-top: 0.2rem;
  li {
    background: #191a1e;
    border-radius: 0.05rem;
    height: 0.5rem;
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    font-size: 0.14rem;
    cursor: pointer;
    img {
      width: 0.26rem;
    }
    &.hover {
      color: white;
    }
  }
}
</style>
