<template>
  <div class="info-bg">
    <div class="info-mode" v-if="!upImg">
      <el-steps :active="stepVal || step" align-center class="info-step">
        <el-step :title="$t('components.order.568383-0')"></el-step>
        <el-step :title="$t('components.order.568383-1')"></el-step>
        <el-step :title="$t('components.order.568383-2')"></el-step>
        <el-step :title="$t('components.order.568383-3')"></el-step>
      </el-steps>
      <div class="order-info">
        <div class="order-info-right">
          <p v-if="stepVal === 4">
            {{ $t("components.order.568383-4") }}
            <span>
              {{ $t("components.order.568383-5") }}<br />{{
                $t("components.order.568383-6")
              }}
            </span>
          </p>
          <p v-else>{{ $t("components.order.568383-7") }}</p>
          <p class="tips" v-if="stepVal === 4">
            {{ $t("components.order.568383-8") }}
          </p>
          <p class="tips" v-else>
            {{ $t("components.order.568383-9")
            }}<span class="text-blue">{{
              $t("components.order.568383-10")
            }}</span>
          </p>
          <!-- 二维码 -->
          <div
            v-if="bankInfo && CKName && [11, 12, 5, 4, 3].includes(info.type)"
            class="pay-code"
          >
            <img :src="bankInfo.payImgUrl" />
            {{ CKName }}
          </div>
          <ul class="info-main">
            <li v-if="CKName">
              <span class="name">{{ CKName }}</span>
            </li>
            <template v-if="bankInfo">
              <li class="one" v-if="info.type === 1">
                <!-- <span class="img"><img src="@/assets/images/userinfo/bank.png"/></span> -->
                <span class="bankName">{{ CKName }}</span>
              </li>
              <li>
                <span class="name">{{ $t("components.order.568383-11") }}</span>
                <span class="main">{{ bankInfo.name }}</span>
                <span
                  class="copy icon-copy"
                  @click="copyLink(bankInfo.name)"
                ></span>
              </li>
              <li v-if="info.type === 1">
                <span class="name">{{ $t("components.order.568383-12") }}</span>
                <span class="main">{{ bankInfo.bankcardNo }}</span>
                <span
                  class="copy icon-copy"
                  @click="copyLink(bankInfo.bankcardNo)"
                ></span>
              </li>
            </template>
            <li v-if="info.usdt && info.type === 4">
              <span class="name">{{ $t("components.order.568383-13") }}</span>
              <span class="main">{{ info.usdt }}</span>
            </li>
            <li>
              <span class="name">{{ $t("components.order.568383-14") }}</span>
              <span class="main">￥{{ Number(info.money).toFixed(2) }}</span>
              <span class="copy icon-copy" @click="copyLink(info.money)"></span>
            </li>
            <li>
              <span class="main" style="color: #7981a4"
                >({{ $t("components.order.568383-15") }})</span
              >
            </li>
            <li v-if="bankInfo">
              <span class="name">{{ $t("components.order.568383-16") }}</span>
              <span class="main">{{
                bankInfo.openBankName || bankInfo.address
              }}</span>
              <span
                class="copy icon-copy"
                @click="copyLink(bankInfo.openBankName || bankInfo.address)"
              ></span>
            </li>
            <li v-if="bankInfo && bankInfo.remark">
              <span class="name">{{ $t("components.order.568383-17") }}</span>
              <span class="main">{{
                bankInfo.remark || $t("components.order.568383-18")
              }}</span>
              <span
                class="copy icon-copy"
                @click="copyLink(bankInfo.remark)"
              ></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="" v-if="stepVal === 4">
        <el-button type="primary" @click="openUrl('/')">{{
          $t("components.order.568383-19")
        }}</el-button>
      </div>
      <template v-if="stepVal !== 4">
        <div class="order-tips">
          <strong>{{ $t("components.order.568383-20") }}</strong>
          <p>
            {{ $t("components.order.568383-21")
            }}<span>{{ $t("components.order.568383-22") }}</span
            >{{ $t("components.order.568383-23") }}
          </p>
          <p>
            {{ $t("components.order.568383-24")
            }}<span>{{ $t("components.order.568383-25") }}</span
            >{{ $t("components.order.568383-26")
            }}<span>{{ $t("components.order.568383-27") }}</span
            >{{ $t("components.order.568383-28") }}
          </p>
          <p>{{ $t("components.order.568383-29") }}</p>
        </div>
        <div class="order-link">
          <el-button @click="canlcePop">{{
            $t("components.order.568383-30")
          }}</el-button>
          <el-button type="primary" @click="submit">{{
            $t("components.order.568383-31")
          }}</el-button>
        </div>
        <div class="order-tip-text">
          <!-- <img src="@/assets/images/userinfo/tips.png" /> -->
          {{ $t("components.order.568383-32")
          }}<span class="text-blue">{{ $t("components.order.568383-33") }}</span
          >{{ $t("components.order.568383-34")
          }}<span class="text-blue">{{ $t("components.order.568383-35") }}</span
          >。
        </div>
      </template>
    </div>
    <!-- 弹出上传凭证 -->
    <div class="popup" ref="uploadImg" v-if="upImg">
      <div class="popup-main" v-loading="loading">
        <div class="popup-title">
          <span class="title">{{ $t("components.order.568383-2") }}</span>
          <span class="close" @click="closePopup"></span>
        </div>
        <div class="popup-zw">
          <p class="small">
            {{ $t("components.order.568383-36") }}
          </p>
          <div class="list">
            <div class="list-tips-tet">
              <p class="tips">
                <span>{{ $t("components.order.568383-37") }}</span
                >{{ $t("components.order.568383-38")
                }}<span>{{ $t("components.order.568383-39") }}</span
                >。
              </p>
            </div>
            <div class="link" @click="openImgShow(1)">
              <span class="h2">{{ $t("components.order.568383-40") }}</span>
              <div class="number">{{ fileList.length }}/1</div>
            </div>

            <el-dialog :visible.sync="imgShowPop" class="jf-img-show-pop">
              <img width="100%" src="@/assets/pic/tips01.png" />
            </el-dialog>
            <el-upload
              action=""
              list-type="picture-card"
              accept=".jpg,.gif,.png,.jpeg"
              :on-preview="handlePictureCardPreview"
              :file-list="fileList"
              :limit="1"
              :http-request="upFileList"
              :on-remove="handleRemove"
              :on-change="handFileList"
              :auto-upload="false"
              :multiple="true"
              ref="upload"
              class="upload-img"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" />
            </el-dialog>
            <div class="list-tips">
              {{ $t("components.order.568383-41") }}
            </div>
          </div>
          <div class="list">
            <div class="list-tips-tet">
              <p class="tips">
                <span>{{ $t("components.order.568383-42") }}</span
                >{{ $t("components.order.568383-43")
                }}<span>{{ $t("components.order.568383-44") }}</span
                >{{ $t("components.order.568383-45")
                }}<span>{{ $t("components.order.568383-46") }}</span
                >～
              </p>
            </div>
            <div class="link" @click="openImgShow(2)">
              <span class="h2">{{ $t("components.order.568383-47") }}</span>
              <div class="number">{{ fileList4.length }}/4</div>
            </div>
            <el-dialog :visible.sync="imgShowPop1" class="jf-img-show-pop">
              <img width="100%" src="@/assets/pic/tips02.png" />
            </el-dialog>
            <el-upload
              action=""
              list-type="picture-card"
              accept=".jpg,.gif,.png,.jpeg"
              :on-preview="handlePictureCardPreview"
              :file-list="fileList4"
              :limit="4"
              :http-request="upFileList"
              :on-remove="handleRemoveLS"
              :on-change="handFileListLS"
              :auto-upload="false"
              :multiple="true"
              ref="upload"
              class="upload-img"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" />
            </el-dialog>
            <div class="list-tips">
              {{ $t("components.order.568383-41") }}
            </div>
          </div>
        </div>
        <div class="popup-footer">
          <el-button @click="canlceOrder">{{
            $t("components.order.568383-48")
          }}</el-button>
          <el-button
            type="primary"
            :disabled="!(fileList4.length && fileList.length)"
            @click="upLoadImg"
            >{{ $t("components.order.568383-49") }}</el-button
          >
        </div>
      </div>
    </div>
    <!-- 回执图片 -->
    <div class="popup1" v-if="imgShow">
      <div class="popup-main">
        <div class="popup-title">
          <span class="title"
            >{{
              imgShow === 1
                ? $t("components.order.568383-40")
                : $t("components.order.568383-50")
            }}
          </span>
          <span class="close" @click="imgShow = null"></span>
        </div>
        <div class="popup-zw-img">
          <img src="@/assets/pic/tips01.png" v-if="imgShow === 1" />
          <img src="@/assets/pic/tips02.png" v-if="imgShow === 2" />
        </div>
      </div>
    </div>
    <!-- 取消订单 -->
    <CancelOrder
      ref="canlcePop"
      :canlceId="info.orderNo"
      v-if="isCancel"
      :visible.sync="isCancel"
    />
  </div>
</template>
<script>
import CancelOrder from "./cancelOrder.vue";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  components: { CancelOrder },
  data() {
    return {
      bankInfo: null,
      upImg: false,
      imgShow: null,
      cancelList: [
        { name: this.$t("components.order.568383-51"), type: 1 },
        { name: this.$t("components.order.568383-52"), type: 2 },
        { name: this.$t("components.order.568383-53"), type: 3 },
        { name: this.$t("components.order.568383-54"), type: 4 },
        { name: this.$t("components.order.568383-55"), type: 5 },
      ],
      cancelIndex: undefined,
      isCancel: false,
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      fileList4: [],
      fileListNew: [],
      fileIndex: 0,
      fileUpLoadList: [],
      loading: false,
      CKName: "",
      imgShowPop: false,
      imgShowPop1: false,
      stepVal: "",
    };
  },
  mounted() {
    this.getSysDictionary();
    this.$nextTick(() => {
      this.getRechargeAccountInfo();
    });
  },
  methods: {
    canlceOrder() {
      this.upImg = false;
      this.fileList = [];
      this.fileList4 = [];
    },
    canlcePop() {
      this.isCancel = true;
    },
    openImgShow(val) {
      if (val === 1) {
        this.imgShowPop = true;
      } else {
        this.imgShowPop1 = true;
      }
    },
    getSysDictionary() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "payment_method_type" })
        .then((res) => {
          if (res) {
            const name = res.sysDictionaryList.find(
              (item) => item.value === this.info.type + ""
            );
            this.CKName = name.name;
          }
        });
    },
    // 上传图片
    upFileList() {
      this.loading = true;
      const arr = this.fileList.concat(this.fileList4);
      const params = {
        files: arr[this.fileIndex].raw,
      };
      this.$Api.upLoadImgPost(params).then((res) => {
        if (res) {
          this.loading = false;
          this.fileUpLoadList.push({
            businessType: arr[this.fileIndex].businessType,
            fileUrl: res[0],
          });
          this.fileIndex++;
          if (this.fileIndex === arr.length) {
            this.confirmRechargeOrder();
            return;
          }
          this.upFileList();
        }
      });
    },
    // 确定提交存款凭证
    confirmRechargeOrder() {
      const params = {
        id: this.info.orderNo,
        fileList: this.fileUpLoadList,
      };
      this.$Api.confirmRechargeOrder(params).then((res) => {
        if (res) {
          this.upImg = false;
          this.stepVal = 4;
          //   const { type, money, orderNo, mode } = this.info;

          //   this.$router.push({
          //     path: this.$router.path,
          //     query: {
          //       type,
          //       mode,
          //       money,
          //       orderNo,
          //       step: 4,
          //     },
          //   });
        }
      });
    },
    // 提交凭证
    upLoadImg() {
      this.$confirm(
        this.$t("components.order.568383-56"),
        this.$t("components.order.568383-57"),
        {
          confirmButtonText: this.$t("components.order.568383-58"),
          cancelButtonText: this.$t("components.order.568383-59"),
          customClass: "el-pop-wid",
        }
      )
        .then(() => {
          this.upFileList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handFileList(file, fileList) {
      fileList.forEach((item) => {
        item.businessType = "recharge_receipt";
      });
      this.fileList = fileList;
    },
    handFileListLS(file, fileList) {
      fileList.forEach((item) => {
        item.businessType = "recharge_flow";
      });
      this.fileList4 = fileList;
    },
    handleRemoveLS(file) {
      this.fileList4 = this.fileList4.filter((item) => {
        if (file !== item) {
          return item;
        }
      });
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => {
        if (file !== item) {
          return item;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    closePopup() {
      this.upImg = false;
    },
    submit() {
      this.$confirm(
        this.$t("deposit.tips.08061533"),
        this.$t("components.order.568383-57"),
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t("components.order.568383-58"),
          cancelButtonText: this.$t("feedback.detail.095266-5"),
          showCancelButton: true,
        }
      )
        .then(() => {
          this.upImg = true;
          const dom = this.$refs.upLoadImg;
          document.body.appendChild(dom);
        })
        .catch(() => {
          console.log(11);
        });
    },
    getRechargeAccountInfo() {
      const { coinType, networkType, type, rechargeType, id } = this.info;
      let params = {
        device: 1,
        rechargeType,
        paymentMethodId: rechargeType === "otherPay" ? id : type,
      };
      if (type === 4) {
        params.coinType = coinType;
        params.networkType = networkType;
      }
      this.$Api.getRechargeAccountInfo(params).then((res) => {
        if (res) {
          this.bankInfo = res.rechargeAccountInfo;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-step__head.is-finish {
  color: white;
  border-color: white;
  .el-step__icon {
    background: #24262b;
  }
  & ~ .el-step__main {
    .el-step__title.is-finish {
      color: white;
    }
  }
}

/deep/.el-step__head.is-wait {
  color: #303133;
  border-color: inherit;
  & ~ .el-step__main {
    .el-step__title.is-wait {
      color: #303133;
    }
  }
}
/deep/.el-step__main {
  .el-step__title {
    font-size: 12px;
    line-height: 30px;
  }
}
.info-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
}
.info-step {
  width: 90%;
}

.info-mode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}
.order-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
}
.order-info-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  ul,
  li {
    width: 100%;
  }
  li {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
}
.pay-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 160px;
  }
}
.info-main {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 80% !important;
  margin: 0 auto;
  li {
    gap: 0.1rem;
  }
}
.order-tips {
  border-radius: 6px;
  border: 1px solid #323338;
  padding: 0.1rem;
  width: 90% !important;
  font-size: 12px;
  line-height: 1.75;
}
.popup-main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.popup-title {
  color: white;
  font-size: 0.16rem;
}
.popup-zw {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0;
  .h2 {
    border-radius: 0.04rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.04rem 0.1rem;
    font-size: 0.12rem;
  }
}

.popup-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.2rem;
  width: 100%;
}
/deep/.el-upload--picture-card {
  width: 120px;
  height: 120px;
  line-height: 118px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
.popup1 {
  position: fixed;
  z-index: 100;
}
</style>
