<template>
  <div class="jf-dep-pop">
    <div class="jf-dep-main">
      <div class="jf-dep-title">
        <div class="left">{{ $t("withdrawal.index.488284-0") }}</div>
        <div class="right icon-close" @click="close"></div>
      </div>
      <div class="jf-dep-content">
        <div class="user-box">
          <div class="user-box-title">
            {{ $t("withdrawal.index.488284-1") }}
          </div>
          <ul class="withdrawal-list">
            <li
              v-for="(item, index) in withdrawMethod"
              :key="index"
              :class="{ hover: withModeInd === index }"
              @click="tabWithMode(item, index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>

        <div class="user-box">
          <div class="transfer-title">
            <div class="left">{{ $t("withdrawal.index.488284-2") }}</div>
          </div>

          <ul class="withdrawal-list">
            <li
              v-for="(item, index) in withType"
              :key="index"
              :class="{ hover: withTypeInd === index }"
              @click="tabWithType(item, index)"
              style="width: 255px"
            >
              {{ item.name }} <span class="small">{{ item.sec }}</span>
              <span class="discount" v-if="item.discount">{{
                item.discount
              }}</span>
            </li>
          </ul>
        </div>
        <Bank
          v-if="withdrawMethod"
          :mode="withdrawMethod"
          :info="withdrawMethod[withModeInd]"
          @update="initBankParams"
        />
        <div class="user-box">
          <div class="user-box-title">
            {{ $t("withdrawal.index.488284-3") }}
          </div>
          <div class="add-bank-list">
            <div class="withModeInfo" v-if="memberLevelList">
              <div class="add-bank-row add-money-number">
                <span class="name">{{ $t("withdrawal.index.488284-4") }}</span>
                <p class="sec">{{ memberLevelList.dailyWithdrawalCount }}</p>
              </div>
              <div class="add-bank-row add-money-number">
                <span class="name">{{ $t("withdrawal.index.488284-5") }}</span>
                <p class="sec" v-if="memberLevelList.dailyWithdrawalAmount">
                  {{ memberLevelList.dailyWithdrawalAmount.toLocaleString() }}
                </p>
              </div>
            </div>
          </div>
          <div>{{ $t("withdrawal.index.488284-6") }}</div>
          <div class="user-money-list">
            <ul v-if="withdrawMethod">
              <li
                v-for="(item, index) in withdrawMethod[withModeInd].quickAmount"
                :key="index"
                class="text-ak"
                :class="{ 'hover-target': index === amountIndex }"
                @click="amountTab(item, index)"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="transfer-input-money">
            <input
              type="text"
              v-model="from.withdrawAmount"
              :placeholder="$t('withdrawal.index.488284-7')"
              oninput="value = value.replace(/[^0-9.]/g,'').replace( /(\.\d{2})\d*/, '$1')"
              class="pay-input"
              @input="checkFrom"
            />
            <span class="unit">RMB</span>
            <span class="all-in" @click="allInMoney">{{
              $t("withdrawal.index.488284-8")
            }}</span>
          </div>
          <div class="usdtTransfer" v-if="usdt && from.withdrawMethodId === 2">
            {{ $t("withdrawal.index.488284-9")
            }}<span class="text-blue"> {{ sumUSDT }} </span>USDT
            <span class="usdtTransfer-sxf">
              {{ $t("withdrawal.index.488284-10")
              }}<span class="text-blue">
                {{ (0).toFixed(4) }}
              </span>
              USDT
            </span>
            <p>
              {{ usdt.name }}1USDT =
              <span>{{ usdt.value.toFixed(4) }}CNY</span>
            </p>
          </div>
          <div v-if="withdrawMethod && withdrawMethod[withModeInd].remark">
            {{ withdrawMethod[withModeInd].remark }}<br /><br />
          </div>
          <div class="withdrawLink" v-if="withdrawMethod">
            <el-button
              type="primary"
              class="wid100"
              :disabled="isSubmit"
              @click="checkPayPWD"
              >{{ $t("withdrawal.index.488284-11") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Bank from "./bank.vue";
export default {
  components: {
    Bank,
  },
  data() {
    return {
      isMoney: true,
      withType: null,
      withModeInd: 0,
      amountIndex: 0,
      withTypeInd: 0,
      walletList: null,
      withdrawMethod: null,
      memberLevelList: null,
      bankList: null,
      // withdrawMethodType: 1快速取款、2USDT提币、3EBpay提币、4KOIpay提币、5支付宝、6微信支付
      from: {
        withdrawClass: "",
        withdrawAmount: "",
        withdrawPassword: "",
        withdrawAccountId: "",
        withdrawAccount: "",
        coinType: "",
        networkType: "",
        withdrawMethodId: "",
        withdrawUserName: "",
        bankName: "",
      },
      accountParams: null,
      isOnePay: null,
      isSetPwd: null,
      usdt: null,
      isSubmit: true,
    };
  },
  computed: {
    ...mapState(["accountMoney", "userInfo"]),
    sumUSDT() {
      if (this.from.withdrawAmount) {
        return (this.from.withdrawAmount / this.usdt.value).toFixed(4);
      } else {
        return (0).toFixed(4);
      }
    },
  },
  watch: {
    userInfo(val) {
      if (val && val.levelId >= 0) {
        this.getVipInfo();
      }
    },
  },
  mounted() {
    this.getWithdrawMethod();
    this.getVipInfo();
    // this.getSysDictionary();
    this.checkIsQuickWithdraw();
    this.checkIsSetWidthPassword();
    this.getUSDT();
  },
  methods: {
    close() {
      this.$store.dispatch("setWithdrawalAc", false);
    },
    // 校验是否显示提交按钮
    checkFrom() {
      const { withdrawAccount, withdrawAmount, withdrawUserName } = this.from;
      if ((withdrawAccount || withdrawUserName) && withdrawAmount) {
        this.isSubmit = false;
        return;
      }
      this.isSubmit = true;
    },
    // 获取usdt汇率
    getUSDT() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "usdt_withdraw_exchange_rate" })
        .then((res) => {
          if (res) {
            res.sysDictionaryList.forEach((item) => {
              item.value = Number(item.value);
            });
            this.usdt = res.sysDictionaryList[0];
          }
        });
    },
    // 输入取款密码
    checkPayPWD() {
      if (this.isSetPwd === "no") {
        // 跳去设置密码
        this.$confirm(
          this.$t("withdrawal.index.488284-12"),
          this.$t("withdrawal.index.488284-13"),
          {
            confirmButtonText: this.$t("withdrawal.index.488284-14"),
            cancelButtonText: this.$t("withdrawal.index.488284-15"),
          }
        )
          .then(() => {
            this.$router.push("/mine/userInfo");
          })
          .catch(() => {});
      } else {
        if (!this.userInfo.mobile && this.regAuth) {
          this.$confirm(
            this.$t("withdrawal.index.488284-16"),
            this.$t("withdrawal.index.488284-13"),
            {
              cancelButtonText: this.$t("withdrawal.index.488284-17"),
              inputPlaceholder: this.$t("withdrawal.index.488284-18"),
              customClass: "el-pop-wid",
            }
          )
            .then(() => {
              this.$router.push("/mine/userInfo");
            })
            .catch(() => {
              console.log(11);
            });
          return;
        }
        // 输入密码
        this.$prompt("", this.$t("withdrawal.index.488284-19"), {
          confirmButtonText: this.$t("withdrawal.index.488284-14"),
          cancelButtonText: this.$t("withdrawal.index.488284-15"),
          inputPlaceholder: this.$t("withdrawal.index.488284-20"),
          customClass: "withdrawPWD",
          inputType: "password",
        })
          .then(({ value }) => {
            this.from.withdrawPassword = value;
            this.submitWithdraw();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 提交取款
    submitWithdraw() {
      this.$Api.getWithdraw(this.from).then((res) => {
        if (res) {
          this.$message({
            type: "success",
            message: this.$t("withdrawal.index.488284-21"),
          });
          this.$router.push("/record/transRecord?type=withdraw");
        }
      });
    },
    // 是否设置取款密码
    checkIsSetWidthPassword() {
      this.$Api.checkIsSetWidthPassword().then((res) => {
        if (res) {
          this.isSetPwd = res.msg;
        }
      });
    },
    // 是否使用过快速转卡
    checkIsQuickWithdraw() {
      this.$Api.checkIsQuickWithdraw().then((res) => {
        if (res) {
          this.isOnePay = res.msg;
        }
      });
    },
    // 全压
    allInMoney() {
      this.from.withdrawAmount = this.accountMoney.userBalance;
    },
    // 获取收钱账号
    initBankParams(val) {
      if (!val) return;
      this.accountParams = val;
      const {
        id,
        bankcardNo,
        coinType,
        networkType,
        name,
        realName,
        accountName,
        bankName,
        address,
        account,
      } = this.accountParams;
      // 初始账号
      this.from.withdrawAccountId = id;
      this.from.withdrawAccount = bankcardNo || address || account;
      this.from.coinType = coinType ? "USDT" : "";
      this.from.networkType = networkType;
      this.from.withdrawUserName = name || realName || accountName;
      this.from.bankName = bankName ? bankName : "";
      this.checkFrom();
    },
    // 取款对应值
    getSysDictionary() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "withdraw_method_type" })
        .then((res) => {});
    },
    // 切换金额
    amountTab(val, index) {
      this.amountIndex = index;
      this.from.withdrawAmount = val;
    },
    // 获取取款金额
    getVipInfo() {
      if (!this.userInfo) return;
      this.$Api.getVipInfo().then((res) => {
        if (res) {
          this.memberLevelList = res.memberLevelList.find(
            (item) => item.levelId === this.userInfo.levelId
          );
        }
      });
    },
    getWithdrawMethod() {
      this.$Api.getWithdrawMethod().then((res) => {
        if (res) {
          res.withdrawMethodList.forEach((item) => {
            if (item.quickAmount) {
              item.quickAmount = item.quickAmount.split(",");
            }
            item.number = 3;
          });
          this.withdrawMethod = res.withdrawMethodList;
          this.getWithViewList();
        }
      });
    },
    // 选择是否包含取款类型
    getWithViewList() {
      const arrVal = [
        {
          name: this.$t("withdrawal.index.488284-22"),
          type: "1",
          sec: "(0-24小时到账)",
          discount: this.$t("withdrawal.index.488284-24"),
        },
        {
          name: this.$t("withdrawal.index.488284-25"),
          type: "2",
          sec: "(即时到账)",
          discount: "",
        },
      ];
      const arr = arrVal.filter(
        (item) =>
          item.type === this.withdrawMethod[this.withModeInd].withdrawType
      );
      if (arr.length) {
        this.withType = arr;
      } else {
        this.withType = arrVal;
      }
      // 初始值
      this.from.withdrawMethodId = this.withdrawMethod[this.withModeInd].id;
      // this.from.withdrawMethodName = this.withdrawMethod[this.withModeInd].name;
      this.from.withdrawClass = this.withType[this.withTypeInd].type;
      this.from.withdrawAmount =
        this.withdrawMethod[this.withModeInd].quickAmount[this.amountIndex];
    },
    // 一键回收
    getOneTouchTransfer() {
      this.$Api.getOneTouchTransfer().then((res) => {
        if (res) {
          this.getUserBalance();
        }
      });
    },
    // 查询金额
    getUserBalance() {
      if (!this.isLogin) return;
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },
    // 切换取钱方式
    tabWithMode(val, index) {
      console.log(val, index);
      if (this.isOnePay === "0" && val.withdrawMethodType !== "1") {
        this.$confirm(
          this.$t("withdrawal.index.488284-27"),
          this.$t("withdrawal.index.488284-13"),
          {
            customClass: "el-pop-wid",
            showCancelButton: false,
          }
        )
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      this.withModeInd = index;
      this.from.bankName = "";
      this.from.withdrawAccount = "";
      this.from.withdrawUserName = "";
      this.getWithViewList();
    },
    tabWithType(val, index) {
      this.withTypeInd = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-list {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
  }

  li {
    position: relative;
    height: 100%;
    flex: 1 1;
    height: 107px;
    padding: 20px 12px;
    background: #f4faff;
    border-radius: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid transparent;

    &:last-child {
      margin-right: 0;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: PingFang SC;
        font-size: 16px;
        line-height: 24px;
        color: #788b9d;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e9f5fe;
        border-radius: 20px;
        width: 84px;
        height: 24px;
        cursor: pointer;
        color: #2599f8;
        font-size: 12px;

        &:hover {
          background: #2599f8;
          color: white;

          .mr {
            display: none;
          }

          .xz {
            display: block;
          }
        }

        img {
          width: 16px;
          height: 16px;

          &.xz {
            display: none;
          }
        }
      }
    }

    .money {
      font-weight: 800;
      font-size: 28px;
      line-height: 35px;
      color: #30383f;
      display: flex;
      align-items: center;

      i {
        width: 12px;
        height: 20px;
        margin-right: 2px;
        // background: url("@/assets/images/mine/icon58.png") no-repeat;
        background-size: 100% 100%;
      }

      img {
        width: 12px;
        height: 20px;
      }
    }
  }
}

.pay-input {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 1px solid transparent;
  background: #17181b;
  padding: 0 0.14rem;
  outline: none;
  color: white;
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--text-1);
    font-size: 14px;
  }
}

.pay-gray-tips,
.pay-red-tips {
  min-height: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #788b9d;
  margin-bottom: 12px;
}

.pay-red-tips {
  color: #e95b5b;
  margin-top: 12px;
}

.user-pay-money {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: -12px;

  li {
    margin-bottom: 12px;
    margin-right: 8px;
    width: 110px;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #f4faff;
    background-color: #f4faff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #30383f;
    position: relative;
    font-family: Akrobat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.transfer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC-Medium;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .left {
    color: white;
  }

  .right {
    display: flex;
    align-items: center;
    font-weight: normal;

    span {
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.transfer-money-list {
  ul {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-top: 16px;

    li {
      width: 166px;
      height: 88px;
      border-radius: 12px;
      margin-right: 12px;
      margin-bottom: 16px;
      padding: 16px 12px;
      position: relative;
      background: #f4faff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #2599f8;
      }

      &:nth-child(4n + 4) {
        margin-right: 0;
      }

      .name {
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #788b9d;
      }

      .money {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #aeb9c4;
        margin-left: 2px;
      }

      .link {
        position: absolute;
        right: 12px;
        top: 14px;
        width: 24px;
        height: 24px;
        background-color: rgba(37, 153, 248, 0.1);
        // background-image: url("@/assets/images/mine/icon59.png");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 50% 50%;
        border: none;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          width: 60px;
          height: 24px;
          border-radius: 20px;
          background: #2599f8;
          transition: all 0.3s;
          outline: none;
          border: none;
          text-align: center;

          &::before {
            content: "一键转入";
            color: white;
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.withdrawal-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  li {
    position: relative;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 14px;
    background: #17181b;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;

    .small {
      font-size: 12px;
    }

    .discount {
      background: #e95b5b;
      border-radius: 10px 10px 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      padding: 1px 4px;
      position: absolute;
      top: -9px;
      right: -6px;
      color: #fff;
    }

    &.hover {
      //   color: #2599f8;
      border: 1px solid var(--text-green2);

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        // background: url("@/assets/images/mine/icon20.png") no-repeat;
        background-size: 100% 100%;
        background-position: right bottom;
      }
    }
  }
}

.add-bank-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.add-bank-row {
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  box-shadow: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  .name {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .sec {
    font-size: 12px;
    margin-top: 4px;
  }
}

.add-money-number {
  padding: 0 40px;
  align-items: flex-start;

  .sec {
    font-size: 20px;
    color: white;
  }
}

.user-money-list {
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.1rem;
  }

  li {
    height: 36px;
    border-radius: 4px;
    border: 1px solid #17181b;
    background-color: #17181b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    &.hover-target {
      border: 1px solid var(--text-green2);
    }
  }
}

.withModeInfo {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  display: flex;
  background: #17181b;

  .add-money-number {
    height: 100%;
    background: none;
    box-shadow: none;
    border-radius: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.bank-bg {
  background-image: linear-gradient(315deg, #d57074, #f7acaf);
  margin-bottom: 12px;
  box-shadow: none;
  color: #788b9d;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 16px;
  position: relative;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  .gou {
    position: absolute;
    top: 6px;
    right: 6px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    width: 18px;
    height: 18px;

    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 6px;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      border-radius: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0.5;
    }
  }

  .bank-name {
    display: flex;
    text-align: left;
    width: 100%;
    flex: 1;

    .icon {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 80%;
        border-radius: 100%;
        overflow: hidden;
      }
    }

    .info {
      color: white;

      &-name {
        font-size: 14px;
      }

      &-type {
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }

  .bank-number {
    color: white;
    text-align: left;
    width: 100%;
    padding-left: 24px;
  }

  .name {
    display: flex;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .sec {
    font-size: 12px;
    margin-top: 4px;
  }
}

.transfer-input-money {
  position: relative;
  display: inline-block;

  .all-in {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    font-family: PingFang SC;
    font-size: 14px;
    line-height: 24px;
    color: white;
    cursor: pointer;
  }

  .unit {
    position: absolute;
    right: 56px;
    top: 50%;
    padding-right: 12px;
    transform: translateY(-50%);

    &::before {
      position: absolute;
      content: "";
      right: 0;
      top: 50%;
      width: 1px;
      height: 14px;
      background: #dcdcdc;
      transform: translateY(-50%);
    }
  }
}

.usdtTransfer {
  margin: 0 0 16px;
  font-size: 12px;

  p {
    margin-top: 6px;
  }
}

.usdtTransfer-sxf {
  padding-left: 10px;
  border-left: 1px solid #eee;
  margin-left: 10px;
}

.jf-dep-pop {
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jf-dep-main {
  margin: 0 auto;
  max-width: 8.4rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #24262b;
}
.jf-dep-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0.44rem;
  padding: 0.1rem 0.14rem;
  background: #17191b;
  .left {
    font-size: 0.16rem;
    color: white;
  }
  .right {
    cursor: pointer;
  }
}
.jf-dep-content {
  flex: 1;
  overflow-y: auto;
  padding: 0.2rem;
  max-height: 8.2rem;
  width: 5.8rem;
  position: relative;
  flex-direction: column;
  gap: 0.3rem;
  display: flex;
}
.user-box-title {
  justify-content: space-between;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sec {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: var(--text-1);
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 2px;
    }
  }
}
.user-box {
  display: flex;
  flex-direction: column;
  gap: 0.12rem;
}
.withdrawLink {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: #17181b;
  .wid100 {
    width: 100%;
  }
}
</style>
