<template>
  <div class="jf-pop">
    <div class="jf-pop-main">
      <div class="pop-banner"></div>
      <div class="pop-from">
        <div class="pop-from-box">
          <div class="pop-title">
            {{
              isLoginShow.type === "register"
                ? $t("components.Login.226102-0")
                : $t("components.Login.226102-1")
            }}
          </div>
          <div class="icon-close close" @click="closePop"></div>
          <div class="jf-from">
            <!-- {{ $t('components.Login.226102-1') }}star -->
            <div class="row">
              <input
                type="text"
                class="jf-input"
                :placeholder="$t('components.Login.226102-2')"
                v-model="from.account"
              />
            </div>
            <div class="row">
              <input
                type="password"
                class="jf-input"
                :placeholder="$t('components.Login.226102-3')"
                v-model="from.pwd"
              />
            </div>
            <div
              class="row"
              v-if="
                isLoginShow.type === 'register' &&
                registerItemList &&
                fromItemShow(7)
              "
            >
              <input
                type="text"
                class="jf-input"
                :placeholder="$t('userInfo.index.388995-1')"
                v-model="from.realName"
              />
            </div>
            <!-- {{ $t('components.Login.226102-1') }}End -->
            <!-- {{ $t('components.Login.226102-0') }}star -->
            <!-- 货币 -->
            <div
              class="row"
              v-if="
                isLoginShow.type === 'register' &&
                registerItemList &&
                fromItemShow(15)
              "
            >
              <el-select
                v-model="from.money"
                :placeholder="$t('components.Login.226102-4')"
                class="jf-select"
              >
                <el-option
                  v-for="item in moneyList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 电话 -->
            <div
              class="row"
              v-if="
                isLoginShow.type === 'register' &&
                registerItemList &&
                fromItemShow(1)
              "
            >
              <el-select
                v-model="from.area"
                :placeholder="$t('components.Login.226102-4')"
                class="jf-select"
                style="width: 2rem"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <input
                type="tel"
                class="jf-input"
                :placeholder="$t('components.Login.226102-5')"
                v-model="from.phone"
              />
            </div>
            <!-- {{ $t('components.Login.226102-0') }}end -->
            <!-- {{ $t('components.Login.226102-1') }}star -->
            <div class="row">
              <input
                type="text"
                class="jf-input"
                :placeholder="$t('components.Login.226102-6')"
                v-model="from.code"
              />
              <img v-lazy="codeImg" class="code" @click="getCaptcha" />
            </div>
            <!-- {{ $t('components.Login.226102-1') }}End -->
            <!-- {{ $t('components.Login.226102-0') }} -->
            <!-- 手机验证码 -->
            <div
              class="row"
              v-if="
                isLoginShow.type === 'register' &&
                registerItemList &&
                fromItemShow(12)
              "
            >
              <input
                type="text"
                class="jf-input"
                :placeholder="$t('components.Login.226102-7')"
                v-model="from.phoneCode"
              />
              <div
                class="text-white"
                v-loading="codeTimeing"
                :class="{ 'no-link': isSengSms }"
                @click="sengSms"
              >
                {{
                  !isSengSms
                    ? $t("components.Login.226102-8")
                    : codeTime + $t("components.Login.226102-9")
                }}
              </div>
            </div>
            <!-- {{ $t('components.Login.226102-10') }} -->
            <div
              class="row"
              v-if="
                isLoginShow.type === 'register' &&
                registerItemList &&
                fromItemShow(18)
              "
            >
              <input
                type="text"
                class="jf-input"
                :placeholder="$t('components.Login.226102-10')"
                v-model="from.invite"
              />
            </div>
            <div class="jf-from-label" v-if="isLoginShow.type === 'register'">
              <label class="jf-check icon-Selected"></label>
              {{ $t("components.Login.226102-11")
              }}<span class="span" @click="openLogin">{{
                $t("components.Login.226102-12")
              }}</span
              >{{ $t("components.Login.226102-13") }}
            </div>
            <div class="jf-from-label" v-else @click="contactLink">
              <span>{{ $t("components.Login.226102-14") }}</span>
            </div>
            <div class="row">
              <button class="btn-green" @click="submit">
                {{ $t("components.Login.226102-1") }}
              </button>
            </div>
            <div
              class="jf-login-tip"
              v-if="isLoginShow.type === 'login'"
              @click="tabFromType('register')"
            >
              {{ $t("components.Login.226102-15")
              }}<span class="a-link-green">{{
                $t("components.Login.226102-16")
              }}</span>
            </div>
            <div class="jf-login-tip" v-else @click="tabFromType('login')">
              {{ $t("components.Login.226102-17")
              }}<span class="a-link-green">{{
                $t("components.Login.226102-1")
              }}</span>
            </div>
            <div class="jf-login-img">
              <img src="@/assets/pic/ad01.webp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { setToken } from "@/utils/token";
import { mapState } from "vuex";
export default {
  data() {
    return {
      checked: true,

      from: {
        account: "",
        pwd: "",
        area: "86",
        phone: "",
        money: "1",
        code: "",
        phoneCode: "",
        invite: "",
        realName: "",
      },
      codeImg: "",
      codeUUid: "",
      moneyList: null,
      areaList: null,
      registerItemList: null,
      isPwd: false,
      // 发送验证码
      codeTime: 60,
      codeTimer: null,
      isSengSms: false,
      codeTimeing: false,
    };
  },
  computed: {
    ...mapState(["isLoginShow"]),
  },
  mounted() {
    this.routePd();
    this.getCaptcha();
    this.getMoneyType();
    this.getArea();
    this.getRegisterItem();
  },
  methods: {
    // 切换from类型
    tabFromType(val) {
      if (val === "login") {
        this.$store.dispatch("setIsLoginShowAc", {
          show: true,
          type: "login",
        });
      } else {
        this.$store.dispatch("setIsLoginShowAc", {
          show: true,
          type: "register",
        });
      }
      this.getCaptcha();
    },
    // from栏是否显示
    fromItemShow(val) {
      let findVal = 0;
      this.registerItemList.map((item) => {
        if (item.id === val) {
          findVal = item.isShow;
        }
      });
      return findVal > 0 ? true : false;
    },
    openLogin() {
      this.openUrl("/terms");
      this.closePop();
    },
    closePop() {
      this.$store.dispatch("setIsLoginShowAc", { show: false, type: "login" });
    },
    sengSmsTime() {
      this.codeTimer = setInterval(() => {
        this.codeTime--;
        if (this.codeTime === 0) {
          clearInterval(this.codeTimer);
          this.isSengSms = false;
          this.codeTime = 60;
        }
      }, 1000);
    },
    sengSms() {
      if (!this.from.phone || !this.from.code) {
        this.$message({
          message: this.$t("components.Login.226102-18"),
          type: "warning",
        });
        return;
      }
      if (this.isSengSms) return;
      this.getPhoneCodeByRegister();
    },
    // 注册发送验证码
    getPhoneCodeByRegister() {
      this.codeTimeing = true;
      const params = {
        codeId: this.codeUUid,
        code: this.from.code,
        smsType: "3",
        mobile: this.from.phone,
      };
      this.$Api.getPhoneCodeByRegister(params).then((res) => {
        this.codeTimeing = false;
        if (res) {
          this.isSengSms = true;
          this.sengSmsTime();
        }
      });
    },
    openContact() {
      window.open(this.baseInfo[6].configValue);
    },
    isFromInput(val) {
      const isShow = this.registerItemList.find((item) => item.id === val);
      const isShowVal = isShow ? (isShow.isShow > 0 ? true : false) : false;
      return isShowVal;
    },
    // 重置
    restFrom() {
      this.from = {
        account: "",
        pwd: "",
        area: "86",
        phone: "",
        money: "1",
        code: "",
        realName: "",
      };
    },
    getMoneyType() {
      this.$Api
        .getSysDictionary({
          dictionaryValue: "currency_type",
        })
        .then((res) => {
          this.moneyList = res.sysDictionaryList;
          this.from.money = res.sysDictionaryList[0].value;
        });
    },
    getArea() {
      this.$Api
        .getSysDictionary({
          dictionaryValue: "area_code",
        })
        .then((res) => {
          res.sysDictionaryList.forEach((item) => {
            item.name = "+" + item.value + " " + item.name;
          });
          this.areaList = res.sysDictionaryList;
        });
    },
    async getCaptcha() {
      this.codeUUid = uuidv4();
      let res = await this.$Api.getCaptcha(this.codeUUid);
      let imgUrl =
        "data:image/png;base64," +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
      this.codeImg = imgUrl;
    },
    submit() {
      if (this.isLoginShow.type === "register") {
        this.registerSend();
      } else {
        this.loginSend();
      }
    },
    checkedFrom() {
      let isFill = true;
      Object.keys(this.from).map((item) => {
        if (!this.from[item] && item !== "invite") {
          isFill = false;
          return;
        }
      });
      return isFill;
    },
    registerSend() {
      if (!this.checkedFrom()) {
        this.$message({
          type: "error",
          message: "注册信息填写不完整",
        });
        return;
      }
      if (!this.checked) {
        this.$message({
          type: "error",
          message: this.$t("components.Login.226102-19"),
        });
        return;
      }
      const {
        account,
        pwd,
        code,
        area,
        phone,
        money,
        phoneCode,
        invite,
        realName,
      } = this.from;
      let urlparams = new URL(location.href).searchParams;
      let [directAgent, invitationCode] = [
        urlparams.get("directAgent"),
        urlparams.get("invitationCode"),
      ];
      const params = {
        userName: account,
        password: pwd,
        code,
        smsCode: phoneCode,
        areaCode: area,
        mobile: phone,
        invitationCode:
          invite || invitationCode || this.$route.query.invitationCode, //邀请码
        directAgent: directAgent, //代理
        currencyType: money,
        codeId: this.codeUUid,
        realName,
      };
      this.$Api.registerSend(params).then((res) => {
        if (res) {
          this.loginSend(true);
          this.restFrom();
          return;
        }
        this.getCaptcha();
      });
    },
    loginSend(val) {
      const { account, pwd, code } = this.from;
      let params = {
        userName: account,
        password: pwd,
        code: code,
        codeId: this.codeUUid,
      };
      if (val) {
        params.loginType = "afterRegister";
      }
      this.$Api.loginSend(params).then((res) => {
        if (res) {
          this.$store.dispatch("setHasLoginAc", true);
          setToken(res.token);
          this.$message({
            message: this.$t("components.Login.226102-20"),
            type: "success",
          });
          this.$router.push("/");
          this.closePop();
          localStorage.setItem("loginState", true);
          return;
        }
        this.getCaptcha();
      });
    },
    // 路由判断
    routePd() {
      if (this.isLoginShow.type === "register") {
        this.getRegisterItem();
      }
    },
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.registerItemList = res.registerItemList;
          let fromCheck = [
            {
              type: "phone",
              id: 1,
            },
            {
              type: "money",
              id: 15,
            },
            {
              type: "code",
              id: 13,
            },
            {
              type: "phoneCode",
              id: 12,
            },
            {
              type: "invite",
              id: 18,
            },
            {
              type: "realName",
              id: 7,
            },
          ];
          res.registerItemList.forEach((item) => {
            fromCheck.map((row) => {
              if (item.id === row.id) {
                item.type = row.type;
              }
            });
            if (item.isShow === 0) {
              delete this.from[item.type];
            }
            // 特殊手机号不显示同时去掉手机区号
            if (item.id === 1 && item.isShow === 0) {
              delete this.from.area;
            }
          });
        }
      });
    },
    openJump(val) {
      this.$router.push(val);
    },
    jumpHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.jf-pop {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.jf-pop-main {
  width: 8rem;
  min-height: 6.5rem;
  display: flex;
  justify-content: center;
  background: #24262b;
  .pop-banner,
  .pop-from {
    width: 50%;
    overflow: hidden;
  }
  .pop-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url("@/assets/pic/register.jpg") no-repeat center;
    background-size: cover;
  }
  .pop-from {
    position: relative;
    background: #1c1e22; //rgba(72, 74, 83, 0.1);
    &-box {
      background: rgba(72, 74, 83, 0.1);
      padding: 0.3rem 0.17rem 0;
      backdrop-filter: blur(7px);
    }
    .close {
      position: absolute;
      right: 0.06rem;
      top: 0.06rem;
      background: #2d3035;
      border-radius: 0.02rem;
      width: 0.3rem;
      height: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text);
      font-size: 0.1rem;
      cursor: pointer;
    }
    .pop-title {
      font-size: 0.13rem;
      font-weight: 700;
      color: #fff;
    }
    .row {
      display: flex;
      align-items: center;
      margin: 0.15rem 0 0.04rem;
      gap: 0.1rem;
      .code {
        height: 0.36rem;
        cursor: pointer;
      }
    }
  }
  .jf-input {
    width: 100%;
    background-color: #1a1c20;
    border-radius: 2px;
    height: 0.38rem;
    color: var(--text);
    text-indent: 0.23rem;
    cursor: pointer;
    border: 1px solid transparent;
    &::placeholder {
      color: var(--text-1);
    }
    &:focus {
      border: 1px solid #3bc117;
      &-visible {
        border: 1px solid #3bc117;
        outline: none;
      }
    }
  }
  .jf-select {
    width: 100%;
    .el-input__inner {
      &:hover {
        border: none;
      }
    }
  }
}
/deep/.jf-select {
  .el-input__inner {
    font-size: 0.12rem;
    text-indent: 0.23rem;
    padding-left: 0;
  }
}
.jf-login-img {
  width: 100%;
  padding: 0.2rem 0;
  img {
    width: 100%;
  }
}
.jf-from-label {
  padding: 0.16rem 0 0.06rem;
  color: var(--text-1);
  font-size: 0.12rem;
  display: flex;
  align-items: center;
  .jf-check {
    width: 0.14rem;
    height: 0.14rem;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.01rem;
    box-sizing: border-box;
    border: 1px solid #2d3035;
    background-color: rgba(45, 48, 53, 0.5);
    font-size: 0.1rem;
    margin-right: 0.07rem;
    color: transparent;
    position: relative;
    cursor: pointer;
    color: var(--text-green1);
    &::before {
      transform: scale(0.7);
      display: block;
    }
  }
  .span {
    color: var(--text);
  }
}
.text-white {
  flex: none;
  cursor: pointer;
}
</style>
